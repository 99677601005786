
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import "~leaflet/dist/leaflet.css";



body {
    font-family: 'Lexend', sans-serif;
    background-color: rgba(255, 255, 255, 0.842);

}

.semibold {
    font-weight: 600; 
}

.bold {
    font-weight: 700;
}

.custom-font {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight:700;
  font-style: normal;
}
.custom-font-title {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight:500;
  font-style: normal;
}

        
.custom-titlefont {
            font-family: 'Montserrat', sans-serif;
        }


.swiper-button-prev:after{
    color: #ffffff
}
.swiper-button-next:after{
    color: #ffffff
}

.highlight {
  color: red;
}
.bg-custom-red {
    background-color: #e61b00;
  }
.bg-custom-red:hover {
    background-color: #c51700;
  }

.text-custom-red {
    color: #0e0e10;
  }

.bg-custom-black:hover {
    background-color: #575757;
  }
.bg-custom-black {
    background-color: #575757;
  }

.text-custom-black {
    color: #e61b00;
  }


.bg-custom-beige {
    background-color: #ffe3cc;
  }

.bg-custom-beige:hover {
    background-color: #ffe3cc;
  }
.bg-custom-gray{
    background-color: #f5f5f5;
  }

.bg-custom-gray:hover {
    background-color: #c7c7c7;
  }
  

.swiper-pagination-progressbar-fill{
    background: #ffffff !important
}



.transition-transform {
    transition-property: transform;
  }
  
  .ease-in-out {
    transition-timing-function: ease-in-out;
  }
  
  .duration-300 {
    transition-duration: 300ms;
  }

.popup-container {
    height: 1633px; /* Set your desired height */
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.cookie-banner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  color: #fff;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1000;
  max-width: 500px;
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
}

.cookie-banner .btn {
  background-color: #ff1e00;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.cookie-banner .btn:hover {
  background-color: #ce1800;
}

.cookie-banner .btn-secondary {
  background-color: #d7d7d7;
}

.cookie-banner .btn-secondary:hover {
  background-color: #8e8e8e;
}

.cookie-banner.hidden {
  display: none;
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

/* Apply shimmer effect */
.animate-shimmer {
  background: linear-gradient(90deg, #cbccd3 25%, #b6b7be 50%, #cbccd3 75%); /* Darker gradient colors */
  background-size: 200% 100%;
  animation: shimmer 3s infinite linear;
}






marker-dot {
  width: 10px;
  height: 10px;
  background-color: #ff0000; /* Red color */
  border-radius: 50%;
  cursor: pointer;
}

.custom-autocomplete .MuiAutocomplete-popupIndicator {
    display: none;
}

.mapboxgl-popup-close-button {
  font-size: 30px;
  padding-right: 4px;
  padding-top: 4px;
  color: white;
}


/* Add this to your component's stylesheet */
.marker {
  cursor: pointer;
  transition: fill 0.3s ease;
}
/* Add this to your component's stylesheet */
.marker:hover {
  fill: rgba(17, 0, 255, 0.7); /* Adjust the last value (0.7) for the desired opacity */
  cursor: pointer;
  transition: fill 0.3s ease;
}

.custom-autocomplete .search-selected .MuiInputBase-input {
  background-color: rgb(230, 230, 230);
  border-radius: 5px;
  border: 1px solid rgb(218, 218, 218); /* Add this line for a black border */
}

/* Add this to your CSS or styling solution */
/* Add this to your CSS or styling solution */
/* Add this to your CSS or styling solution */
underline-red {
  display: inline-block;
  position: relative;
}

underline-red::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 2px; /* Adjust this value to control the distance between text and underline */
  width: 100%;
  height: 7px;
  background-color: red; /* You can adjust the color as needed */
}

.noDropdown .MuiAutocomplete-popupIndicator {
  display: none;
}


@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


/* Add these classes to your CSS file */
.map-container {
  display: block; /* By default, map container is visible */
}

.map-container.hidden {
  display: none; /* Hide map container when this class is applied */
}


.expandedMap {
  height: 250px;
}

.no-scroll {
  overflow: hidden;
}


/* styles.css */
.text-super-small {
  font-size: 0.5rem; /* Adjust the value to make the text as small as you need */
}
/* styles.css */
.text-small {
  font-size: 0.6rem; /* Adjust the value to make the text as small as you need */
}

/* styles.css */
.text-three {
  font-size: 1.6rem; /* Adjust the value to make the text as small as you need */
}

/* Custom CSS for Slider arrows */
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  z-index: 10; /* Ensure arrows are above the slider content */
  width: 30px; /* Set the width of the arrows */
  height: 30px; /* Set the height of the arrows */
  border-radius: 50%; /* Make the arrows circular */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.7; /* Adjust the opacity of the arrows */
  color: #c7c7c7;
  
}

.slick-arrow.slick-prev {
  left: 0px; /* Set the distance from the left */
}

.slick-arrow.slick-next {
  right: 0px; /* Set the distance from the right */
}
.red-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
}

/* Increase icon size */
.slick-arrow.slick-prev::before,
.slick-arrow.slick-next::before {
  font-size: 35px; /* Adjust the font size of the icons */
}

/* On hover, make arrows fully opaque */
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover {
  opacity: 0.9; /* Set opacity to 1 on hover */
    color: #c7c7c7;

}

body.popup-open {
  position: fixed;
 
}


.custom-menu-item {
  background-color: white;
}

.custom-menu-item:hover,
.custom-menu-item.Mui-selected {
  background-color: white;
}

.custom-menu-item:hover .MuiInputLabel-root,
.custom-menu-item.Mui-selected .MuiInputLabel-root {
  background-color: white;
}


.custom-image-gallery .image-gallery-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

/* Define a custom class to apply the shadow with 50% opacity */
.custom-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Adjust the rgba values for the desired opacity */
}

 /* Add this to your CSS file */
.text-field-cursor {
  cursor: text !important; /* Ensures the cursor is a text cursor */
  caret-color: black; /* Sets the color of the text cursor */
}

/* CSS file or style block */
.cursor-black .MuiInputBase-input {
  caret-color: black; /* This sets the cursor color */
}

.sticky-button {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 40; /* Make sure it stays on top */
}
.hidden {
  display: none;
}

.MuiDialog-paper {
  border-radius: 0.75rem; /* Adjust the value to control the roundness */
}

.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000; /* Make sure it's on top of other content */
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.loading-bar-container {
  position: relative;
  width: 100%;
  height: 5px;
  background: none;
  margin-top: 0px;
}

.loading-bar-background {
  width: 100%;
  height: 5px; /* Same height as the loading bar */
  background-color: #dfdfdf; /* Grey background bar */
  position: relative;
}

.loading-bar {
  height: 100%;
  background-color: #e61b00; /* Red color */
  transition: width 0.3s ease; /* Smooth transition */
}
